import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Login from './Login';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import validator from 'validator';
import { ToastContainer, toast } from 'react-toastify';
import { selectCurrentUser, userLogin, userLogout, userCredits} from '../authSlice';
import { clearSession } from '../imageSlice';
import { useSelector } from 'react-redux';
import RequestService from '../RequestService';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { Button, Typography, Alert, AlertTitle, LinearProgress, Box} from '@mui/material';
import { Dialog, DialogContent, DialogTitle, DialogActions  } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import ScrollTopFab from './ScrollToTopFab';

export function LoginPage() {
    const location = useLocation();
    const SITE_KEY = process.env.REACT_APP_SITE_KEY;
    const [registerMessage, setRegisterMessage] = useState([]);
    const [registerInfoMessage, setRegisterInfoMessage] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [enableRegister, setEnableRegister] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [showTc, setShowTc] = useState(false);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
        
            if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
                if (callback) callback();
            };
            document.body.appendChild(script);
            }
        
            if (isScriptExist && callback) callback();
        }

            const hash = location.hash;
            if (hash === '#register') {
              switchTabs(1);
            }
        
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {

        });
        // Disable auto-zoom on mobile
        if (isMobile) {
          const viewport = document.querySelector('meta[name="viewport"]');
          if (viewport) {
            viewport.content = 'width=device-width, initial-scale=1, maximum-scale=1';
          }
        }
    }, [location]);

    function switchTabs(index) {
        setTabIndex(index);
        setRegisterMessage([]);
        setRegisterInfoMessage([]);
    }

    const handleOnClick = e => {
        e.preventDefault();
        setLoading(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            register(token);
          });
        });
      }

    function toggleField (elementId) {

        var x = document.getElementById(elementId);
        if (x) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
        
    }


   const navigateHome = () => {
        navigate(location.state?.from || '/');
   };


    const refreshTokenSetup  = (res) => {
        // Timing to renew access token
        let refreshTiming = (3600 - 1 * 60) * 1000;
         const refreshToken = async () => {
           //Implement the logic here, for now logout the user
           // Setup the other timer after the first one
           dispatch(userLogout());
           dispatch(clearSession());
         };
       
         setTimeout(refreshToken, refreshTiming);
    }

    function handleClose() {
        setOpenTerms(false);
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior (e.g., submitting a form)
            login();
        }
    }

    function handleCloseTc() {
        setShowTc(false);
    }

    function resetPassword() {
        setShowMessage(false);
        setRegisterMessage([]);
        var proceed = true;
        var messages = [];
        var userName = document.getElementById('loginUserName').value;
        if (validator.isEmpty(userName) || !validator.isEmail(userName)) {
            messages.push('Enter your email to reset the password');
            proceed = false;
        }
        if (proceed) {
            //Post to backend 
            setProcessing(true)
            RequestService.resetLink(JSON.stringify({
                email: userName
             })).then((response) => {
                setProcessing(false);
                if (response.status == 200) {
                    messages.push("Please check you email for the password reset instructions");
                    messages.push('Typically, you should receive the link to reset your password in your email immediately. In the rare instance that you do not get it right away, please allow up to an hour. If you still have not received the reset password link after this period, kindly reach out to us through the Contact Us form.');
                    setShowMessage(true);
                    setRegisterInfoMessage(messages);
                } else  {
                    messages.push("An error occurred, unable to reset password at this time. Please try again later.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
                }
              })
              .catch((err) => {
                console.log(err);
                messages.push("An error occurred, unable to reset password at this time. Please try again later.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
            });   
        } else {
            setShowMessage(true);
            setRegisterMessage(messages);
        }
    }

// Function to handle timeout for fetch
const fetchWithTimeout = (url, options = {}, timeout = 5000) => {
  const controller = new AbortController();
  const signal = controller.signal;

  // Set a timeout for the fetch request
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  // Fetch request with timeout handling
  return fetch(url, { ...options, signal })
    .then((response) => {
      clearTimeout(timeoutId);
      return response;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        throw new Error('Request timed out');
      }
      throw error;
    });
};

function login() {
  setShowMessage(false);
  setRegisterMessage([]);

  var messages = [];
  var userName = document.getElementById('loginUserName').value;
  var password = document.getElementById('loginPassword').value;
  var proceed = true;

  // Validate username and password
  if (!validator.isEmail(userName) || validator.isEmpty(userName)) {
    messages.push('Invalid user name');
    proceed = false;
  }

  if (validator.isEmpty(password)) {
    proceed = false;
    messages.push('Password is required');
  }

  if (!proceed) {
    setRegisterMessage(messages);
  } else {
    setProcessing(true);

    // Fetch the user's public IP address with a timeout
    fetchWithTimeout('https://api.ipify.org?format=json', {}, 5000) // 5 seconds timeout
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch IP address');
        }
        return response.json();
      })
      .then((data) => {
        // Proceed with login and send the IP along with the login request
        const userObject = {
          username: userName,
          password: password,
          address: data.ip, // Add IP to the login request
        };

        return RequestService.authenticate(JSON.stringify(userObject));
      })
      .catch((err) => {
        console.log('IP fetch error:', err.message);
        // If the IP fetch fails or times out, send null as userIp
        const userObject = {
          username: userName,
          password: password,
          userIp: null, // Set IP to null if not available
        };

        return RequestService.authenticate(JSON.stringify(userObject));
      })
      .then((response) => {
        if (response.status == 200) {
          setProcessing(false);
          var userObject = {};
          userObject.token = response.data.jwttoken;
          userObject.name = response.data.name;
          userObject.emailVerified = response.data.tokenVerified;
          userObject.email = jwtDecode(response.data.jwttoken).sub;

          if (userObject.emailVerified === true) {
            refreshTokenSetup(userObject);
            dispatch(userLogin(userObject));

            RequestService.getViewCredits(userObject.token).then((response) => {
              if (response.status == 200) {
                dispatch(userCredits(response.data.creditsRemaining));
              }
            });

            navigateHome();
          } else {
            if (response.data.name === 'agreedToTermsAndConditions_false') {
              // Show the terms and conditions
              setShowTc(true);
            }
          }
        } else {
          messages.push("Username or Password is incorrect");
          setShowMessage(true);
          setRegisterMessage(messages);
        }
      })
      .catch((err) => {
        console.log(err);
        messages.push("Username or Password is incorrect");
        setShowMessage(true);
        setRegisterMessage(messages);
      });
  }
}


    function handleOpen() {
        setOpenTerms(true);
    }

    function register(token) {
        var name = document.getElementById('registerName').value;
        var email = document.getElementById('emailAddress').value;
        var password = document.getElementById('password').value;
        var retypePassword = document.getElementById('retypePassword').value;
        var customerType = document.getElementById('customerType').value;
        var messages = [];
        var proceed = true;
        if (password != retypePassword) {
            messages.push('Password does not match');
            proceed = false;
        }

        if (validator.isEmpty(password)) {
            messages.push('Password is blank');
            proceed = false;
        }

        if (validator.isEmpty(retypePassword)) {
            messages.push('Retype the password');
            proceed = false;
        }

        if (validator.isEmpty(email)) {
            messages.push('Email is blank');
            proceed = false;
        }

        if (!validator.isEmail(email)) {
            messages.push('Email is not valid');
            proceed = false;
        }

        if (validator.isEmpty(name)) {
            messages.push('Name is blank');
            proceed = false;
        }

        if (!validator.isStrongPassword(password)) {
            messages.push('Password is not Strong');
        }

        if (!proceed) {
            setShowMessage(true);
            setRegisterMessage(messages)
        } else {
            setRegisterMessage([]);
            setProcessing(true);
        }


        if (proceed) {
            RequestService.register(JSON.stringify({
                name: name,
                email: email,
                password: password,
                captchaToken: token,
                customerType: customerType,
                agreedToTermsAndConditions: 1
             })).then((response) => {
                setProcessing(false);
                if (response.status == 200) {
                    messages.push('An email has been sent to the registered email address ' + email); 
                    messages.push('Typically, you should receive the activation link in your email immediately. In the rare instance that you do not get it right away, please allow up to an hour. If you still have not received the activation link after this period, kindly reach out to us through the Contact Us form.'); 
                    messages.push('Activate your account by verifying your email');
                    setRegisterInfoMessage(messages);
                    setShowMessage(true);
                    setTabIndex(0);
                    document.getElementById('registerName').value = '';
                    document.getElementById('emailAddress').value = '';
                    document.getElementById('password').value = '';
                    document.getElementById('retypePassword').value = '';
                    toast.success(response.data);
                } else {
                    toast.error('An error occurred registering the user. Please try again later.');
                }
              })
              .catch(() => {
                toast.error('An error occurred registering the user. Please try again later.')
            });
        }
        
    }

    function enableButton() {
        
        if (document.getElementById('tc').checked) {
            setEnableRegister(true);
            setAcceptedTerms(true);
        } else {
            setEnableRegister(false);
            setAcceptedTerms(false);
        }
    }

    return (
    currentUser ? <div>Logged in as {currentUser}. If you would like to use different login, please lougout and login.</div> :
    <Box m={5} width={"100%"} alignContent={"center"} justifyContent={"center"} alignItems={"center"} display="flex" flexDirection="column" >
    {registerInfoMessage.length > 0 && (
        <Box width={"50%"}>
        <Alert severity='info'>
          <AlertTitle>Info</AlertTitle>  
          <ul>
            {registerInfoMessage.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </Alert>
        </Box>    
      )}    
    <Box m={5} width={isMobile ? "90%" : "25%"}>
        <ToastContainer position='top-center' theme='dark'/>
        <fieldset>
            
            <Tabs selectedIndex={tabIndex} onSelect={(index) => switchTabs(index)}>
                <TabList>
                    <Tab><a href="#register">Login</a></Tab>
                    <Tab><a href="#register">Register</a></Tab>
                </TabList>
                {registerMessage.length > 0 && (
        <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
          <ul>
            {registerMessage.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </Alert>
      )}
                <TabPanel id="login">
                    <table className='App-table'>
                        <tr>
                            <td>
                                <label><Typography variant='body2'>Username:</Typography> </label>
                            </td>
                            <td>
                                <input type="text" name="userName" id="loginUserName" size={25}/>
                            </td>
                        </tr>
                        <tr>
                        <td>
                            <label><Typography variant='body2'>Password:</Typography> </label>
                            </td>
                            <td>
                                <input type="password" name="password" id="loginPassword" size={25} onKeyDown={handleKeyDown}/>
                                <FaEye id="showLoginPassword" onClick={() => toggleField("loginPassword")}/>
                            </td>
                        </tr>
                        <tr>
                            <td ><Button onClick={login} variant="contained" size='small'>Login</Button></td>
                            <td>
                                <Button onClick={resetPassword}>Forgot Password</Button>
                            </td>
                        </tr>
                    </table>
                    {processing ? <LinearProgress/>: ''}
                   
                </TabPanel>
                <TabPanel id="register">
                <table className='App-table'>
                        <tr>
                            <td>
                                <label><Typography variant='body2'>Tell us who you are:</Typography> </label>
                                </td>
                                <td>
                                    <select id="customerType">
                                        <option value="BUYER">Buyer</option>
                                        <option value="SELLER">Seller</option>
                                        <option value="BUILDER_SALES_AGENT">Builder Sales Agent</option>
                                        <option value="REAL_ESTATE_AGENT">Real Estate Agent</option>
                                    </select>
                                </td>
                        </tr>
                         <tr>
                            <td>
                                <label><Typography variant='body2'>Name:</Typography> </label>
                            </td>
                            <td>
                                <input type="text" name="name" id="registerName" size={25}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Email Address: </label>
                            </td>
                            <td>
                                <input type="text" name="emailAddress" id="emailAddress" size={25}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label><Typography variant='body2'>Password:</Typography>  </label>
                                </td>
                                <td>
                                    <input type="password" name="password" id="password" size={25}/>
                                    <FaEye id="showPassword" onClick={() => toggleField("password")}/>
                                </td>
                        </tr>
                        <tr>
                            <td>
                                <label><Typography variant='body2'>Retype Password:</Typography> </label>
                                </td>
                                <td>
                                    <input type="password" name="retypePassword" id="retypePassword" size={25}/>
                                    <FaEye id="showRetypePassword" onClick={() => toggleField("retypePassword")}/>
                                </td>
                        </tr>
                        <tr>
                                <td colSpan={2}>
                                    <label><Typography variant='body2'><a href="#" onClick={handleOpen}>Accept Terms & Conditions/Disclaimer</a>
                                    <input type="checkbox" name="tc" id="tc" onChange={enableButton} checked={acceptedTerms}/>
                                    </Typography> </label>

                                </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><Button variant='contained' size="small" onClick={handleOnClick} disabled={!enableRegister}>Register</Button></td>
                        </tr>
                    </table>
                    {processing ? <LinearProgress/>: ''}
                </TabPanel>
            </Tabs>
        </fieldset>
        </Box>        
        <Box width={isMobile ? "90%" : "25%"} alignContent={"center"} justifyContent={"center"} alignItems={"center"} display="flex" flexDirection="column" ><Typography variant='heading6'>OR</Typography></Box>
        <Box m={5} width={isMobile ? "90%" : "25%"}>
        <fieldset>
            <div className='App-login'>
                <Login home={navigateHome} routeLocation={location}/>
                <br></br>
                <Typography variant='caption'><a href="#" onClick={handleOpen}>Our terms & conditions and disclaimer</a></Typography>
            </div>
            <Dialog
                  maxWidth={"md"}
                  open={showTc}
                >
                  <DialogTitle>Accept Terms of Service and Disclaimer </DialogTitle>
                  <DialogContent>
                  <Typography><input type="checkbox" id="acceptTc"></input> <a href="#" onClick={handleOpen}>Accept Terms of Service</a></Typography>
                  <Button>Submit</Button>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseTc}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>

            <Dialog
                  maxWidth={"md"}
                  open={openTerms}
                >
                  <DialogTitle>vastureport.com (owned by Swift Vision LLC) - Terms of Service and Disclaimer</DialogTitle>
                  <DialogContent>



<div class="legal-container-document-dialog">
{/* <h2>vastureport.com (owned by Swift Vision LLC) - Terms of Service</h2> */}

<p>These Terms of Service govern your use of the website located at <a href="https://vastureport.com">https://vastureport.com</a> and any related services provided by Swift Vision LLC. </p>
<p>By accessing <a href="https://vastureport.com">https://vastureport.com</a>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by Swift Vision LLC. </p>
<p>We, Swift Vision LLC, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication. </p>
<p>These Terms of Service were last updated on 8 December 2023. </p>
<h3>Limitations of Use</h3>
<p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not: </p>
<ol>
   <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
   <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
   <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
   <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Swift Vision LLC provides;</li>
   <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
   <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
   <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
   <li>harvest, collect, or gather user data without the user’s consent; or</li>
   <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
</ol>
<h3>Intellectual Property</h3>
<p>The intellectual property in the materials contained in this website are owned by or licensed to Swift Vision LLC and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use. </p>
<p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Swift Vision LLC at any time. </p>
<h3>Liability</h3>
<p>Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Swift Vision LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights. </p>
<p>In no event shall Swift Vision LLC or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Swift Vision LLC or an authorized representative has been notified, orally or in writing, of the possibility of such damage. </p>
<p>In the context of this agreement, &ldquo;consequential loss&rdquo; includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity, or otherwise. </p>
<p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. </p>
<h3>Accuracy of Materials</h3>
<p>The materials appearing on our website are not comprehensive and are for general information purposes only. Swift Vision LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website. </p>
<h3>Links</h3>
<p>Swift Vision LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by Swift Vision LLC of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites. </p>
<h3>Right to Terminate</h3>
<p>We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service. </p>
<h3>Severance</h3>
<p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected. </p>
<h3>Governing Law</h3>
<p>These Terms of Service are governed by and construed in accordance with the laws of Minnesota. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location. </p>

<h1>Disclaimers for vastureport.com (owned by Swift Vision LLC)</h1>

<p>All the information on this website - vastureport.com (owned by Swift Vision LLC) - is published in good faith and for general information purpose only. vastureport.com (owned by Swift Vision LLC) does not make any warranties about the completeness, reliability and accuracy of this information.The Vastuscore™, reports and other information provided by vastureport.com (owned by Swift Vision LLC) should not be considered as a substitute for professional advice, diagnosis, or treatment. Vastu principles are based on cultural and traditional beliefs and may not be supported by scientific evidence. Moreover, any actions or decisions taken based on Vastu advice are solely at the individual's discretion and risk. vastureport.com (owned by Swift Vision LLC) will not be liable for any losses and/or damages in connection with the use of our website.It is important to use common sense and personal judgment in making decisions that are best for you and your surroundings.</p>
<p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.</p>

<p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>

<h2>Consent</h2>

<p>By using our website, you hereby consent to our disclaimer and agree to its terms of service.</p>

<h2>Update</h2>

<p>Should we update, amend or make any changes to this document, those changes will be prominently posted on our website.</p>

    </div>


                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
        </fieldset>
       </Box>
       </Box>
    
    )
}

export default LoginPage;