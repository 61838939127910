import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import {userLogin, userLogout, selectCurrentUser, selectCurrentToken, userCredits} from '../authSlice'
import config from '../config';
import usePageView from '../components/usePageView'; // Import the custom hook

// Function to handle timeout for fetch (used to fetch IP address with timeout)
const fetchWithTimeout = (url, options = {}, timeout = 5000) => {
  const controller = new AbortController();
  const signal = controller.signal;

  // Set a timeout for the fetch request
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return fetch(url, { ...options, signal })
    .then((response) => {
      clearTimeout(timeoutId);
      return response;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        throw new Error('Request timed out');
      }
      throw error;
    });
};

export function Success() {
  usePageView('Purchase Credit Success');

  let [searchParams] = useSearchParams();
  const [message, setMessage] = useState([]);
  const params = [];
  const dispatch = useDispatch();

  for (let entry of searchParams.entries()) {
    params.push(entry);
  }

  useEffect(() => {
    const callParams = [];
    const messages = [];

    params.map((param) => {
      param.map((value, index) => {
        if (index === 1) {
          callParams.push(value);
        }
      });
    });

    // Fetch the user's public IP address with a timeout
    fetchWithTimeout('https://api.ipify.org?format=json', {}, 5000)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch IP address');
        }
        return response.json();
      })
      .then((data) => {
        // Proceed with verifyToken and send the IP address as 'address'
        return fetch(config[process.env.NODE_ENV].backendUrl + '/verifyToken', {
          method: 'POST',
          body: JSON.stringify({
            tokenIdentifier: callParams[0],
            agreedToTermsAndConditions: 0,
            address: data.ip, // Add IP address here
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
      })
      .catch((err) => {
        console.log('IP fetch error:', err.message);
        // If the IP fetch fails or times out, proceed with null as the address
        return fetch(config[process.env.NODE_ENV].backendUrl + '/verifyToken', {
          method: 'POST',
          body: JSON.stringify({
            tokenIdentifier: callParams[0],
            agreedToTermsAndConditions: 0,
            address: null, // Set to null if not available
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
      })
      .then((response) => response.json())
      .then((data) => {
        const tokenObject = jwtDecode(callParams[0]);
        const userObject = {
          token: callParams[0],
          name: data.name,
          emailVerified: data.tokenVerified,
          email: tokenObject.email,
        };

        if (data.tokenVerified === true) {
          dispatch(userLogin(userObject));

          // Proceed with payment success
          RequestService.paymentSuccess(callParams[1], callParams[0]).then((response) => {
            if (response.status === 200) {
              if (response.data.status === 'PAID') {
                const creditsAdded = response.data.totalCreditsAdded;
                RequestService.getViewCredits(callParams[0]).then((response) => {
                  if (response.status === 200) {
                    dispatch(userCredits(response.data.creditsRemaining));
                    setMessage(`Payment Succeeded and ${creditsAdded} credit(s) were added to your account. You can check the order details in My Orders.`);
                  }
                });
              }
            } else {
              setMessage('Please check back after a few minutes.');
            }
          }).catch((err) => {
            messages.push('Unable to activate the user. The activation token has expired');
            setMessage(messages);
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [params, dispatch]);

  return <p className='App-p'>{message}</p>;
}


export default Success;