import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ToastContainer, toast } from 'react-toastify';
import config from '../config';
import { Grid } from '@mui/material';
import report_sample_page_1 from '../assets/images/report_sample_page_1.png';
import report_sample_page_2 from '../assets/images/report_sample_page_2.png';
import expanded_vastu_criteria_multiple_bedrooms_toilets from '../assets/images/expanded-vastu-criteria-multiple-bedrooms-toilets.png';
import plot_shape_selection_vastu from '../assets/images/plot-shape-selection-vastu.png';
import streets_around_plot_vastu from '../assets/images/streets-around-plot-vastu.png';
import plot_street_focus_vastu from '../assets/images/plot-street-focus-vastu.png';
import CloseIcon from '@mui/icons-material/Close'; // Direct import of CloseIcon




import { Dialog, DialogContent, IconButton } from '@mui/material';



export function VastuCalculatorPage() {

    const [score, setScore] = useState(null); // State to hold the score

      const [isImageModalOpen, setImageModalOpen] = useState(false);
      const [selectedReportImage, setSelectedReportImage] = useState(null);

      // Function to handle opening the image modal
      const handleImageClick = (image) => {
        setSelectedReportImage(image);
        setImageModalOpen(true);
      };

      // Function to handle closing the image modal
      const handleImageModalClose = () => {
        setImageModalOpen(false);
        setSelectedReportImage(null);
      };


    const [selectedValues, setSelectedValues] = useState({
        'Main Door': '',
        'Living Room/Hall': '',
        'Kitchen': '',
        'Master Bedroom': '',
        'Children\'s Bedroom': '',
        'Pooja': '',
        'Bathroom/Toilet': '',
        'Garage/Car Parking': '',
    });

  const faqs = [
    {
      question: 'What is Vastu?',
      answer: 'Vastu, also known as Vastu Shastra, is an ancient Indian science that focuses on creating harmonious and balanced living environments. It involves principles and guidelines for designing spaces in a way that aligns with the natural forces and energies. Vastu emphasizes the arrangement of elements within a property to promote well-being, prosperity, and positive energy flow. The practice takes into account factors such as direction, layout, and the placement of rooms to create a balanced and auspicious living space.',
    },
    {
      question: 'Why do people look for homes that have stronger Vastu compliance?',
      answer: 'People look for homes with stronger Vastu compliance to ensure a harmonious and balanced living environment. A home with better Vastu alignment is believed to promote positive energies, which can contribute to improved health, prosperity, harmony, and success. By seeking properties with strong Vastu compliance, individuals aim to create a space that aligns with Vastu principles, fostering a positive and auspicious atmosphere for themselves and their families.',
    },
    {
      question: 'Why should I use VastuReport.com?',
      answer: 'Traditionally, evaluating Vastu requires reaching out to a Vastu expert, incurring costs and consuming time. If you want to compare multiple properties, it becomes more expensive and time-consuming. VastuReport.com changes this dynamic by providing instant Vastu scores, ratings, and detailed reports within seconds at a low cost. Our platform facilitates faster decision-making by offering:',
      additionalInfo: [
        'Instant Vastu scores, ratings, and detailed reports for swift decision-making.',
        'Accessibility to Vastu-enhanced living for individuals.',
        'Invaluable tools for builder sales agents and real estate agents to enhance service quality and cater to the growing demand for Vastu-compliant properties.',
        'Empower builders to create properties with stronger Vastu compliance, meeting the rising expectations for harmonious and balanced living spaces.',
      ]
    },
    {
      question: 'What evaluation do we do?',
      answer: 'We perform a thorough Vastu assessment of your property based on the details you provide. This includes a detailed report with a Vastuscore™ and rating, offering insights into the alignment and energies of each room or plot. The analysis focuses on aspects such as health, prosperity, harmony, and success in your home.',
    },
    {
      question: 'What does the Vastuscore™ represent?',
      answer: 'The Vastuscore™ is a numerical representation ranging from 0 to 100, gauging the level of Vastu compliance for any given property. A higher score indicates better adherence to Vastu principles.',
    },
    {
      question: 'How is the overall rating determined based on the Vastuscore™?',
      answer: 'The overall rating is determined according to the following table:\n\n\
      - Score 0-29: Least Favorable\n\
      - Score 30-49: Unfavorable\n\
      - Score 50-69: Average\n\
      - Score 70-89: Good\n\
      - Score 90 and above: Excellent\n\
      \nThis rating system helps you quickly assess the Vastu compliance level of a property.',
    },
    {
      question: 'Can I get a detailed report for my property?',
      answer: 'Yes, a Vastuscore™ calculation provides you a detailed Vastu report for your property, which includes the score, recommendations, and other relevant information.',
    }
    // ... (other FAQ items)
  ];


    const navigate = useNavigate();

  const calculateScore = () => {
      navigate('/myreports');
    }

   const optionsMap = {
    'Main Door': {
        code: 'main_door',
        isMandatory: true,
        options: [
        { label: 'N5 - Soma entrance (North: 0 - 11.25°)', value: 'N5' },
        { label: 'N6 - Bhujang entrance (North: 11.25 - 22.5°)', value: 'N6' },
        { label: 'N7 - Aditi entrance (Northeast: 22.5 - 33.75°)', value: 'N7' },
        { label: 'N8 - Diti entrance (Northeast: 33.75 - 45°)', value: 'N8' },
        { label: 'E1 - Shikhi entrance (Northeast: 45 - 56.25°)', value: 'E1' },
        { label: 'E2 - Parjanya entrance (Northeast: 56.25 - 67.5°)', value: 'E2' },
        { label: 'E3 - Jayanta entrance (East: 67.5 - 78.75°)', value: 'E3' },
        { label: 'E4 - Indra entrance (East: 78.75 - 90°)', value: 'E4' },
        { label: 'E5 - Surya entrance (East: 90 - 101.25°)', value: 'E5' },
        { label: 'E6 - Satya entrance (East: 101.25 - 112.5°)', value: 'E6' },
        { label: 'E7 - Bhrisha entrance (Southeast: 112.5 - 123.75°)', value: 'E7' },
        { label: 'E8 - Akash entrance (Southeast: 123.75 - 135°)', value: 'E8' },
        { label: 'S1 - Anil entrance (Southeast: 135 - 146.25°)', value: 'S1' },
        { label: 'S2 - Poosha entrance (Southeast: 146.25 - 157.5°)', value: 'S2' },
        { label: 'S3 - Vitatha entrance (South: 157.5 - 168.75°)', value: 'S3' },
        { label: 'S4 - Grihrakshita entrance (South: 168.75 - 180°)', value: 'S4' },
        { label: 'S5 - Yama entrance (South: 180 - 191.25°)', value: 'S5' },
        { label: 'S6 - Gandharva entrance (South: 191.25 - 202.5°)', value: 'S6' },
        { label: 'S7 - Bhringraj entrance (Southwest: 202.5 - 213.75°)', value: 'S7' },
        { label: 'S8 - Mriga entrance (Southwest: 213.75 - 225°)', value: 'S8' },
        { label: 'W1 - Pitra entrance (Southwest: 225 - 236.25°)', value: 'W1' },
        { label: 'W2 - Dwarika entrance (Southwest: 236.25 - 247.5°)', value: 'W2' },
        { label: 'W3 - Sugriva entrance (West: 247.5 - 258.75°)', value: 'W3' },
        { label: 'W4 - Pushpdanta entrance (West: 258.75 - 270°)', value: 'W4' },
        { label: 'W5 - Varuna entrance (West: 270 - 281.25°)', value: 'W5' },
        { label: 'W6 - Nakaratma entrance (West: 281.25 - 292.5°)', value: 'W6' },
        { label: 'W7 - Shauka entrance (Northwest: 292.5 - 303.75°)', value: 'W7' },
        { label: 'W8 - Papyakshama entrance (Northwest: 303.75 - 315°)', value: 'W8' },
        { label: 'N1 - Roga entrance (Northwest: 315 - 326.5°)', value: 'N1' },
        { label: 'N2 - Naga entrance (Northwest: 326.5 - 337.5°)', value: 'N2' },
        { label: 'N3 - Mukhya entrance (North: 337.5 - 348.75°)', value: 'N3' },
        { label: 'N4 - Bhallat entrance (North: 348.75 - 0°)', value: 'N4' }
        ]
      },
         'Living Room/Hall': {
                 code: 'living_room',
                 isMandatory: true,
                 options: [
           { label: 'East', value: 'E' },
           { label: 'West', value: 'W' },
           { label: 'North', value: 'N' },
           { label: 'South', value: 'S' },
           { label: 'North East', value: 'NE' },
           { label: 'South East', value: 'SE' },
           { label: 'North West', value: 'NW' },
           { label: 'South West', value: 'SW' },
         ]
         },
      'Kitchen': {
       code: 'kitchen',
       isMandatory: true,
       options: [
        { label: 'North', value: 'N' },
        { label: 'North of Northeast', value: 'NNE' },
        { label: 'Northeast', value: 'NE' },
        { label: 'East of Northeast', value: 'ENE' },
        { label: 'East', value: 'E' },
        { label: 'East of Southeast', value: 'ESE' },
        { label: 'Southeast', value: 'SE' },
        { label: 'South of Southeast', value: 'SSE' },
        { label: 'South', value: 'S' },
        { label: 'South of Southwest', value: 'SSW' },
        { label: 'Southwest', value: 'SW' },
        { label: 'West of Southwest', value: 'WSW' },
        { label: 'West', value: 'W' },
        { label: 'West of Northwest', value: 'WNW' },
        { label: 'Northwest', value: 'NW' },
        { label: 'North of Northwest', value: 'NNW' }
      ]
      },
     'Master Bedroom': {
          code: 'master_bedroom',
          isMandatory: true,
          options: [
       { label: 'East', value: 'E' },
       { label: 'West', value: 'W' },
       { label: 'North', value: 'N' },
       { label: 'South', value: 'S' },
       { label: 'North East', value: 'NE' },
       { label: 'South East', value: 'SE' },
       { label: 'North West', value: 'NW' },
       { label: 'South West', value: 'SW' }
     ]
     },
     'Children\'s Bedroom': {
                                   code: 'childrens_bedroom',
                                           isMandatory: false,

                                   options: [
       { label: 'East', value: 'E' },
       { label: 'West', value: 'W' },
       { label: 'North', value: 'N' },
       { label: 'South', value: 'S' },
       { label: 'North East', value: 'NE' },
       { label: 'South East', value: 'SE' },
       { label: 'North West', value: 'NW' },
       { label: 'South West', value: 'SW' }
     ]
     },
     'Pooja': {
                     code: 'pooja_room',
                                           isMandatory: false,

                     options: [
       { label: 'East', value: 'E' },
       { label: 'West', value: 'W' },
       { label: 'North', value: 'N' },
       { label: 'South', value: 'S' },
       { label: 'North East', value: 'NE' },
       { label: 'South East', value: 'SE' },
       { label: 'North West', value: 'NW' },
       { label: 'South West', value: 'SW' }
     ]
     },
     'Bathroom/Toilet': {
                               code: 'toilet',
                                                                          isMandatory: false,

                               options: [
        { label: 'North', value: 'N' },
        { label: 'North of Northeast', value: 'NNE' },
        { label: 'Northeast', value: 'NE' },
        { label: 'East of Northeast', value: 'ENE' },
        { label: 'East', value: 'E' },
        { label: 'East of Southeast', value: 'ESE' },
        { label: 'Southeast', value: 'SE' },
        { label: 'South of Southeast', value: 'SSE' },
        { label: 'South', value: 'S' },
        { label: 'South of Southwest', value: 'SSW' },
        { label: 'Southwest', value: 'SW' },
        { label: 'West of Southwest', value: 'WSW' },
        { label: 'West', value: 'W' },
        { label: 'West of Northwest', value: 'WNW' },
        { label: 'Northwest', value: 'NW' },
        { label: 'North of Northwest', value: 'NNW' }
     ]
     },
     'Garage/Car Parking': {
                                  code: 'garage',
                                  options: [
       { label: 'East', value: 'E' },
       { label: 'West', value: 'W' },
       { label: 'North', value: 'N' },
       { label: 'South', value: 'S' },
       { label: 'North East', value: 'NE' },
       { label: 'South East', value: 'SE' },
       { label: 'North West', value: 'NW' },
       { label: 'South West', value: 'SW' }
     ]
     }
   };

    // Handle dropdown change
const handleSelectChange = (title, selectedValue) => {
    const selectedOption = optionsMap[title].options.find(option => option.value === selectedValue);
    const code = selectedOption.code; // Get the code for the selected option

    setSelectedValues(prev => ({
        ...prev,
        [title]: { value: selectedValue, code } // Store both value and code
    }));
};

// Function to handle timeout for fetch
const fetchWithTimeout = (url, options = {}, timeout = 5000) => {
  const controller = new AbortController();
  const signal = controller.signal;

  // Set a timeout for the fetch request
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  // Fetch request with timeout handling
  return fetch(url, { ...options, signal })
    .then((response) => {
      clearTimeout(timeoutId);
      return response;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        throw new Error('Request timed out');
      }
      throw error;
    });
};

// Function to submit the selected values to the backend
function submitCalculateVastu() {
  // Check if all mandatory fields are filled before proceeding
  for (const [title, selected] of Object.entries(selectedValues)) {
    const { isMandatory } = optionsMap[title];
    const { value } = selected || {};

    if (isMandatory && !value) {
      alert(`Please select a value for: ${title}`);
      return; // Exit the whole function if a mandatory field is not filled
    }
  }

  // Build the query after all mandatory fields are checked
  const query = Object.entries(selectedValues).reduce((acc, [title, selected]) => {
    const { code } = optionsMap[title];
    const { value } = selected || {};

    if (code && value) {
      acc.push({
        objectCode: code,
        matchCondition: value,
      });
    }
    return acc;
  }, []);

  const obj = { query };

  // Fetch the user's public IP address with timeout
  fetchWithTimeout('https://api.ipify.org?format=json', {}, 5000) // 5 seconds timeout
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch IP address');
      }
      return response.json();
    })
    .then((data) => {
      // Add the IP address to the object
      obj.identifier = data.ip;
    })
    .catch((err) => {
      console.log(err.message); // Log the error message
      obj.identifier = null; // Set userIp to null if fetching IP fails
    })
    .finally(() => {
      // Proceed with the original fetch to calculate the Vastu score
      fetch(config[process.env.NODE_ENV].backendUrl + '/calculateBasicScore', {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text(); // Assuming the response is a string
          } else {
            toast.error('Failed to calculate Vastu score.');
            throw new Error('Failed to calculate score');
          }
        })
        .then((score) => {
          setScore(score); // Update the score state only if successful
          toast.success('Vastu score calculated successfully!');
        })
        .catch((err) => {
          setScore(null); // Clear existing score and set to null on any error
          toast.error('An error occurred while calculating Vastu score.');
          console.log(err.message);
        });
    });
}


  useEffect(() => {
    console.log('Rendering YourComponent', selectedValues);
  }, [selectedValues]);

  return (
    <Box sx={{ width: '80%', margin: '30px auto', fontSize: '14px', textAlign: 'left'}}>
      <Helmet>
        <title>Vastu Calculator - Evaluate Your Home's Vastu Online</title>
        <meta name="description" content="Unlock the harmony of your home with our Vastu Calculator. Evaluate your home's Vastu Score and receive a detailed report with our advanced calculator." />
        <meta name="keywords" content="vastu calculator, online vastu check, check vastu online free, online vastu consultant free, vastu evaluator, home energy assessment, vastu analysis, home vastu test, vastu shastra score, vastu shastra calculator, vastu check online" />
        <link rel="canonical" href="https://vastureport.com/calculate-vastu" />
      </Helmet>
      <Typography variant="h1" sx={{ fontSize: '28px', fontWeight: 600, marginBottom: '16px' }}>
        Vastu Calculator - Evaluate Your Home's Vastu Score Online and Receive a Detailed Report
      </Typography>

    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      Unlock the harmony of your home with our Vastu Calculator. Evaluate your home's Vastu Score and receive a detailed report with our advanced calculator.
      Choose the options given below and find the overall Home <strong>Vastu compliance</strong>. The first calculation and detailed report are absolutely free! Discover the Vastu insights for your first home evaluation at no cost.
      Our our advanced <strong>Vastu Calculator</strong> goes beyond aesthetics, delving into the intricate energy patterns that shape your home.
    </Typography>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginTop:'40px' ,marginBottom: '12px' }}>
      Why <strong>Vastu Shastra</strong> Matters?
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      <strong>Vastu Shastra</strong> is an ancient Indian architectural science that aligns living spaces with the natural forces to enhance positive energy flow.
      By understanding the balance of the five elements and directional influences, <strong>Vastu</strong> creates a harmonious environment that positively impacts your physical, mental, and spiritual well-being.
    </Typography>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginTop:'40px' ,marginBottom: '12px' }}>
      Why should you use a <strong>Vastu Shastra</strong> calculator?
    </Typography>
    <Typography>
    Welcome to our Vastu Calculator page, where we explore the intricate balance between interior design and the ancient principles of Vastu. When organizing your living spaces according to the principles of Vastu Shastra, it's essential to consider each room's orientation and purpose.
    <br></br><br></br>
    For instance, the living room, often the heart of the home, should ideally be situated in an area conducive to positive energy flow. Similarly, the dining room, where families gather to share meals and connect, benefits from a Vastu-compliant layout to enhance positive vibes during mealtimes.
    <br></br><br></br>
    Rooms like the study room and pooja room hold specific significance in Vastu Shastra, as they cater to focused activities and spiritual practices, respectively. By aligning these spaces with Vastu principles, you can create environments conducive to productivity and inner peace.
    <br></br><br></br>
    Our Vastu Calculator not only provides practical vastu tips but also empowers you to make informed decisions about your home design. Whether you're setting up a home office or creating a cozy family room, our platform offers invaluable insights to help you align your living spaces with the principles of Vastu.
    <br></br><br></br>
    By incorporating these principles into your interior design choices, you can transform your home into a sanctuary of positivity and well-being. Utilizing a Vastu score calculator can provide valuable insights into the overall Vastu compliance of your home.

    Ultimately, incorporating Vastu Shastra into your home design fosters positive energy flow and creates a harmonious atmosphere conducive to well-being and prosperity. Whether it's optimizing the layout of your living room or enhancing the tranquility of your pooja room, embracing Vastu principles can transform your home into a sanctuary of positivity and abundance.
 </Typography>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginTop:'50px',marginBottom: '12px' }}>
      How Our <strong>Vastu Calculator</strong> Works
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      Our <strong>Vastu Calculator</strong> performs a meticulous evaluation, assessing key elements that influence the energy dynamics of your home:
    </Typography>

      <br></br>
      <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
        Room Placement Assessment
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Main Door:</strong> The entry point for energy; evaluate its location for optimal vibes.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Kitchen:</strong> The heart of the home; assess its position for overall well-being.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Living Room:</strong> The social hub; examine its layout for positive interactions.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Master Bedroom:</strong> Your sanctuary; analyze its arrangement for optimal energy flow.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Children's Bedroom:</strong> Nurture positivity; evaluate its setup for a harmonious living environment.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Guest Bedroom:</strong> Welcome positive vibes; assess the arrangement and energy flow.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Garage:</strong> Ensure positive energy flow; examine its placement.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Study Room:</strong> Foster learning; analyze its location for a conducive environment.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Pooja Room:</strong> Spiritual center; evaluate its placement for enhanced energy.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Dining Room:</strong> Balance and nourishment; assess its arrangement for a harmonious atmosphere.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Toilet:</strong> Maintain energy harmony; examine its placement and design.
          </Typography>
        </li>
      </ul>
      <br></br>
        <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
         Plot Shape, Size and Direction Assessment
       </Typography>
       <Typography variant="body1" sx={{ marginBottom: '16px' ,marginLeft:'30px'}}>
         Consider the shape, size and direction of your plot to ensure alignment with Vastu principles for stability and positive energy.
       </Typography>
<br></br>
       <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
         Surroundings Examination
       </Typography>
       <Typography variant="body1" sx={{ marginBottom: '16px' ,marginLeft:'30px'}}>
         Assess the influence of natural elements and nearby water bodies on energy.
       </Typography>
<br></br>
       <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
         Streets Around the Plot Evaluation
       </Typography>
       <Typography variant="body1" sx={{ marginBottom: '16px' , marginLeft:'30px'}}>
         Evaluate the impact of roads surrounding your property on energy flow.
       </Typography>
<br></br>
<br></br>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginBottom: '12px' }}>
      <strong>Vastu Score Calculator</strong> - Assess Your Home's Positive Energy
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: '16px', align: 'middle' }}>
      This is a free basic version of our <strong>Vastu Calculator</strong>, providing a high-level understanding of your home's <strong>Vastu compliance</strong>. For a more accurate and detailed analysis, we highly recommend signing up! Our advanced <strong>Vastu Calculator</strong> offers additional options and criteria for a more thorough assessment, ensuring a harmonious living environment tailored to your preferences and needs.
      <br></br>

      <br></br>
<div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>

{Object.entries(optionsMap).map(([title, { code, isMandatory, options }]) => {
  const selectedLabel = selectedValues[title];

  return (
    <div key={title} style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '150px',
      flex: '1 0 calc(50% - 10px)',
      marginBottom: '20px'
    }}>
      <Typography variant="h3" sx={{ fontSize: '17px', fontWeight: 500, marginBottom: '12px' }}>
        {title}
        {isMandatory && (
                    <span style={{ color: 'red' }}> *</span>
                )} :
      </Typography>
      <select
        style={{
          width: '80%', // Full width to allow responsiveness
          minWidth: '150px', // Minimum width for dropdown
          fontSize: '16px', // Adjust font size for mobile
          boxSizing: 'border-box' // Ensures padding is included in width
        }}
        value={selectedLabel || ''} // Ensure this reflects the current state
        onChange={(event) => {
          const selectedValue = event.target.value;
          const selectedOption = options.find(option => option.value === selectedValue);
          handleSelectChange(title, selectedOption.value); // Pass the value instead of the object
        }}
      >
        <option value="" disabled>Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
})}

</div>


<Accordion
  sx={{
    marginBottom: '16px',
    border: '1px solid #4CAF50',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
  }}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: '#4CAF50' }} />}
    aria-controls="panel1-content"
    id="panel1-header"
    sx={{
      backgroundColor: '#E8F5E9',
      borderRadius: '8px',
      padding: '5px 10px',
      '&:hover': {
        backgroundColor: '#C8E6C9',
      },
    }}
  >
    <Typography variant="h3" sx={{ fontSize: '18px', fontWeight: 600, color: '#388E3C' }}>
        Do you have multiple bedrooms or bathrooms to evaluate, or need additional criteria like plot shape or street direction?
      <span style={{ color: '#1976D2', cursor: 'pointer', fontWeight: 700 }}>  Click here to learn more!</span>
    </Typography>
  </AccordionSummary>

  <AccordionDetails sx={{ backgroundColor: '#F1F8E9', padding: '10px', borderRadius: '0 0 8px 8px' }}>
    <Typography variant="body1" sx={{ color: '#444', marginBottom: '16px' }}>
      If you sign up, you can access advanced features to evaluate multiple bedrooms, bath or toilet, assess the plot shape, street direction, compare properties and more. Unlock these tools by signing up for free!
    </Typography>

      <div>
      <Grid container spacing={2}>
        {/* Image 1 - Larger (on the left) */}
        <Grid item xs={12} sm={5}>
          <Typography variant="body2" sx={{ marginBottom: '12px', textAlign: 'center',fontWeight: 'bold' }}>
            Assess multiple bedrooms, toilets, and additional criteria for precise Vastu evaluation
          </Typography>
          <img
            src={expanded_vastu_criteria_multiple_bedrooms_toilets}
            alt="Expanded Vastu Criteria - Multiple Bedrooms, Toilets, and More"
            style={{
              width: '100%',
              height: 'auto', // Keep height auto for better scaling
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
            }}
            onClick={() => handleImageClick(expanded_vastu_criteria_multiple_bedrooms_toilets)}
          />
        </Grid>

        {/* Remaining Images - Stacked (on the right) */}
        <Grid container item xs={12} sm={7} direction="column" spacing={2}>
          {/* Image 2 */}
          <Grid item>
            <Typography variant="body2" sx={{ marginBottom: '12px', textAlign: 'center',fontWeight: 'bold' }}>
              Choose from a variety of plot shapes to enhance your Vastu alignment.
            </Typography>
            <img
              src={plot_shape_selection_vastu}
              alt="Plot Shape Selection for Vastu Analysis"
              style={{
                width: '100%',
                height: 'auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(plot_shape_selection_vastu)}
            />
          </Grid>

          {/* Image 3 */}
          <Grid item>
            <Typography variant="body2" sx={{ marginBottom: '12px', textAlign: 'center',fontWeight: 'bold' }}>
              Analyze the impact of surrounding streets on your plot’s Vastu alignment.
            </Typography>
            <img
              src={streets_around_plot_vastu}
              alt="Streets Around the Plot for Vastu Evaluation"
              style={{
                width: '100%',
                height: 'auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(streets_around_plot_vastu)}
            />
          </Grid>

          {/* Image 4 */}
          <Grid item>
            <Typography variant="body2" sx={{ marginBottom: '12px', textAlign: 'center' ,fontWeight: 'bold'}}>
              Assess the directional impact of street focus on your plot for precise Vastu evaluation.
            </Typography>
            <img
              src={plot_street_focus_vastu}
              alt="Plot Street Focus for Vastu Analysis"
              style={{
                width: '100%',
                height: 'auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(plot_street_focus_vastu)}
            />
          </Grid>
        </Grid>
      </Grid>


        {/* Reuse the existing modal */}
        <Dialog open={isImageModalOpen} onClose={handleImageModalClose} maxWidth="md">
          <DialogContent>
          </DialogContent>
        </Dialog>
      </div>

    {/* Sign Up Button */}
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <Link
        to="/login#register" // Assuming this is the sign-up route
        style={{
          fontSize: '18px',
          fontWeight: 600,
          display: 'inline-block',
          margin: '16px 0',
          padding: '10px 20px',
          color: '#FFFFFF',
          backgroundColor: '#1976D2',
          textDecoration: 'none',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(25, 118, 210, 0.2)',
        }}
      >
        Sign Up for Free to Unlock All Features
      </Link>
    </div>
  </AccordionDetails>
</Accordion>



<div style={{ textAlign: 'center', marginTop: '20px' }}>
{/* Score display and Calculate Score button */}
{score === "-1"? (
  <>
    <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#D32F2F', marginBottom: '16px' }}>
      You have exceeded the maximum attempts for the day.
    </div>
    <div style={{ fontSize: '18px', marginBottom: '12px' }}>
      Please sign up to run a free report or try again tomorrow.
    </div>
    <Link
      to="/login#register" // Assuming this is the sign-up route
      style={{
        fontSize: '18px',
        fontWeight: 600,
        display: 'inline-block',
        margin: '16px 0',
        padding: '10px 20px',
        color: '#FFFFFF',
        backgroundColor: '#1976D2',
        textDecoration: 'none',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(25, 118, 210, 0.2)',
      }}
    >
      Sign Up for Free
    </Link>
  </>
) : score ? (
  <>
    <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#1976D2' }}>
      Vastuscore™ : {score}
    </div>
    <Link
      to="#"
      onClick={submitCalculateVastu} // For recalculating the score
      style={{
        fontSize: '18px',
        fontWeight: 600,
        display: 'inline-block',
        marginTop: '16px',
        padding: '10px 20px',
        color: '#FFFFFF',
        backgroundColor: '#1976D2',
        textDecoration: 'none',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(25, 118, 210, 0.2)',
      }}
    >
      Recalculate Your <strong>Vastu Score</strong>
    </Link>
  </>
) : (
  <Link
    to="#"
    onClick={submitCalculateVastu}
    style={{
      fontSize: '18px',
      fontWeight: 600,
      display: 'inline-block',
      margin: '16px 0',
      padding: '10px 20px',
      color: '#FFFFFF',
      backgroundColor: '#1976D2',
      textDecoration: 'none',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(25, 118, 210, 0.2)',
    }}
  >
    Calculate Your <strong>Vastu Score</strong>
  </Link>
)}

  {/* Centered Title at the top */}
  <Typography
    variant="h5"
    sx={{
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#FF5722',
      margin: '20px 0', // Margin for spacing
      textAlign: 'center', // Ensure the title is centered
    }}
  >
    Unlock the Full Potential of Your Home with a Detailed Vastu Report!
  </Typography>

     <div style={{ marginTop: '20px', backgroundColor: '#F7F7F7', padding: '20px', borderRadius: '8px' }}>
       {/* Grid for responsiveness */}
       <Grid container spacing={4}>
         {/* Left side: List of points */}
         <Grid item xs={12} md={6}>
           <Typography variant="body1" sx={{ fontSize: '16px', color: '#555', marginBottom: '10px' }}>
             Sign up now to receive an exclusive, personalized Vastu report with advanced analysis and insights. Your first detailed report is completely FREE—no credit card required!
           </Typography>
           <Typography variant="body1" sx={{ fontSize: '16px', color: '#555', marginBottom: '10px' }}>
             Here’s what you get:
           </Typography>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '10px', textAlign: 'left' }}>
             <li>A <strong>Vastuscore™</strong> and rating for your property.</li>
             <li>Detailed insights for each room or plot, explaining the Vastu compliance.</li>
             <li>Compare the Vastu of multiple properties or construction plans.</li>
             <li>Share your Vastu report with others via a simple link.</li>
             <li>Get a customized report to highlight your property’s strengths when selling it.</li>
             <li>Make informed decisions when modifying your home to see the Vastu impact.</li>
           </ul>

           {/* Sign-up button */}
           <div style={{ textAlign: 'center', marginTop: '35px' }}>
             <Link
               to="/login#register"
               style={{
                 fontSize: '20px',
                 fontWeight: 700,
                 display: 'inline-block',
                 padding: '12px 24px',
                 color: '#FFFFFF',
                 backgroundColor: '#FF5722',
                 textDecoration: 'none',
                 borderRadius: '6px',
                 boxShadow: '0 4px 8px rgba(255, 87, 34, 0.3)',
               }}
             >
               Sign Up Now and Get Your Free Report!
             </Link>
           </div>
         </Grid>

         {/* Right side: Images - Responsive */}
         <Grid item xs={12} md={6} style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
           <img
             src={report_sample_page_1}
             alt="Vastu Report Sample Page 1"
             style={{
               width: '100%',
               maxWidth: '250px',
               height: 'auto',
               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
               cursor: 'pointer',
             }}
             onClick={() => handleImageClick(report_sample_page_1)}
           />
           <img
             src={report_sample_page_2}
             alt="Vastu Report Sample Page 2"
             style={{
               width: '100%',
               maxWidth: '250px',
               height: 'auto',
               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
               cursor: 'pointer',
             }}
             onClick={() => handleImageClick(report_sample_page_2)}
           />
         </Grid>
       </Grid>

       {/* Modal for displaying the clicked image */}
    <Dialog
      open={isImageModalOpen}
      onClose={handleImageModalClose}
      maxWidth="lg" // Allow modal to resize up to a larger width
      fullWidth
      PaperProps={{
        style: {
          position: 'relative',
          width: 'auto', // Let the modal's width adapt based on content
          maxWidth: '90vw', // Max width to ensure it doesn’t go beyond the viewport
          maxHeight: '90vh', // Limit modal height to viewport
        },
      }}
    >
      {/* Close Button at top right */}
      <IconButton
        aria-label="close"
        onClick={handleImageModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        sx={{
          padding: 0, // Remove padding for a cleaner look
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {/* Image that resizes to fit the screen */}
        <img
          src={selectedReportImage}
          alt="Selected Report Image"
          style={{
            maxWidth: '100%', // Ensures image scales down to fit modal width
            maxHeight: '90vh', // Ensures image scales down to fit modal height
            objectFit: 'contain', // Ensures image retains aspect ratio
          }}
        />
      </DialogContent>
    </Dialog>
     </div>
     </div>

    </Typography>

    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      Uncover the secret to a harmonious home – where positive energies thrive, and well-being flourishes.
      Embrace the profound wisdom of <strong>Vastu Shastra</strong> and create a living space that resonates with positivity.
    </Typography>

    <br></br><br></br>
    <Typography variant="h5" sx={{ marginBottom: '16px' }}>
      <strong>Vastu Evaluation - Frequently asked Questions</strong>
    </Typography>
    <br></br>
     {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ marginBottom: '16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-${index}-content`}
                id={`faq-${index}-header`}
              >
                <Typography variant="h3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
    </Box>
  );
};

export default VastuCalculatorPage;
